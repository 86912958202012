@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

@import "variables";

@import "~bootstrap";

@import '~@fortawesome/fontawesome-free/scss/variables';
@import '~@fortawesome/fontawesome-free/scss/mixins';
@import '~@fortawesome/fontawesome-free/scss/core';
@import '~@fortawesome/fontawesome-free/scss/larger';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';

.#{$fa-css-prefix}-bell:before {
  content: fa-content($fa-var-bell);
}
.#{$fa-css-prefix}-arrow-right:before {
  content: fa-content($fa-var-arrow-right);
}
.#{$fa-css-prefix}-angle-right:before {
  content: fa-content($fa-var-angle-right);
}
.#{$fa-css-prefix}-envelope:before {
  content: fa-content($fa-var-envelope);
}
.#{$fa-css-prefix}-star:before {
  content: fa-content($fa-var-star);
}
.#{$fa-css-prefix}-star-half:before {
  content: fa-content($fa-var-star-half);
}
.#{$fa-css-prefix}-check:before {
  content: fa-content($fa-var-check);
}
.#{$fa-css-prefix}-times:before {
  content: fa-content($fa-var-times);
}
.#{$fa-css-prefix}-thumbs-up:before {
  content: fa-content($fa-var-thumbs-up);
}
.#{$fa-css-prefix}-thumbs-down:before {
  content: fa-content($fa-var-thumbs-down);
}
.#{$fa-css-prefix}-square:before {
  content: fa-content($fa-var-square);
}

@import "global";