body {
  background-image: url("../../public_html/assets/images/star-bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: top center;
}

.main {
  min-height: calc(100vh - 320px);
}

.navbar-brand img {
  width: 210px;
}

.nav-link {
  font-weight: 600;
}

.star-rating {
  color: $yellow;
  font-size: 0.8rem;
}

.offer-card {
  ul {
    list-style: square;

    li::marker {
      content: "♦ ";
      display: block;
      color: $purple;
    }
  }

  .rating {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    color: $white;
    background-color: orange;
    border: 5px solid #ffe5e5;
    border-radius: 50%;
    text-align: center;

    p {
      font-size: 1.5rem;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      font-weight: 600;
    }
  }
}

.ribbon {
  position: relative;
  text-align: center;
  line-height: 1.2;
  display: inline-block;

  &.ribbon-preferred {
    .ribbon-inner {
      background-color: $blue;

      &::before {
        left: -10px;
        border-top: 10px solid $blue;
        border-bottom: 10px solid $blue;
      }

      &::after {
        right: -10px;
        border-top: 10px solid $blue;
        border-bottom: 10px solid $blue;
      }
    }
  }

  &.ribbon-popular {
    .ribbon-inner {
      background-color: $purple;

      &::before {
        left: -10px;
        border-top: 10px solid $purple;
        border-bottom: 10px solid $purple;
      }

      &::after {
        right: -10px;
        border-top: 10px solid $purple;
        border-bottom: 10px solid $purple;
      }
    }
  }

  .ribbon-inner {
    height: 20px;
    padding: 0 15px;
    color: $white;

    &::before,
    &::after {
      content: "";
      position: absolute;
      display: block;
    }

    &::before {
      top: 0;
      border-left: 10px solid transparent;
    }

    &::after {
      top: 0;
      border-right: 10px solid transparent;
    }

    span {
      font-size: 0.8rem;
    }
  }
}

.featured-card {
  background-color: $dark-blue;
  color: $white;

  .logo {
    background-color: $white;
    border-radius: $border-radius;
    padding: 0.8rem 0;
    margin-bottom: 0.5rem;
  }

  ul {
    li::marker {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f00c";
      display: block;
      color: $green;
    }
  }
}

.positive-list {
  li::marker {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f00c";
    display: block;
    color: $green;
  }
}

.negative-list {
  li::marker {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f068";
    display: block;
    color: $red;
  }
}

.accordion {
  .card:not(:last-of-type) {
    overflow: unset;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
  }

  .btn-link {
    font-weight: 600;
    &:focus {
      box-shadow: none;
    }
  }
}

.btn-sm {
  font-size: 0.75rem;
}

.footer {
  a {
    color: $white;

    &:hover {
      color: $white;
    }
  }

  h3 {
    border-bottom: 3px solid $green;
  }
}

.side-offer {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100vh;
  background-color: $white;
  z-index: 999;
  border-radius: 0;
  border: 0;
  box-shadow: 5px 0 15px rgba(0, 0, 0, 0.8);

  .card-header {
    background-color: $yellow;
    .close {
      opacity: 1;
    }
  }
}

.pop-up-offer {
  .close {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

@media (min-width: 992px) {
  .navbar-brand img {
    width: 100%;
  }

  .offer-card {
    .rating {
      width: 80px;
      height: 80px;
    }
    p {
      font-size: 2rem;
    }
  }

  .star-rating {
    letter-spacing: 2px;
    font-size: 1rem;
  }

  .pop-up-offer {
    .close {
      top: 0.8rem;
      right: 1rem;
    }
  }

  .ribbon {
    position: relative;
    line-height: 1.7;
    display: block;

    &.ribbon-preferred {
      .ribbon-inner {
        background-color: $blue;

        &::before {
          border-top: 12px solid darken($blue, 10%);
          border-bottom: 0;
          left: 0;
        }

        &::after {
          border-left: 15px solid $blue;
          border-top: 15px solid transparent;
          border-bottom: 15px solid transparent;
          right: -25px;
        }
      }
    }

    &.ribbon-popular {
      .ribbon-inner {
        background-color: $purple;

        &::before {
          border-top: 12px solid darken($purple, 10%);
          border-bottom: 0;
          left: 0;
        }

        &::after {
          border-left: 15px solid $purple;
          border-top: 15px solid transparent;
          border-bottom: 15px solid transparent;
          right: -25px;
        }
      }
    }

    .ribbon-inner {
      height: 30px;
      padding: 0 15px;
      position: absolute;
      left: -27px;
      top: 0;
      color: $white;

      &::before,
      &::after {
        content: "";
        position: absolute;
        display: block;
      }

      &::before {
        height: 0;
        width: 0;
        top: 29.5px;
        border-left: 22px solid transparent;
      }

      &::after {
        top: 0;
        height: 0;
        width: 0;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
      }

      span {
        font-size: 1rem;
      }
    }
  }
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.privacy-container h1 {
  margin-top: 2rem;
}
